.bg-img {
    background-image: url('../../img/header_background_7-512.webp');
    margin-top: -75px;
    padding-top: 100px;
    z-index: 99;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
/* For tablets */
@media (min-width: 768px) {
    .bg-img {
        background-image: url('../../img/header_background_7-768.webp');
    }
}
/* For desktops */
@media (min-width: 992px) {
    .bg-img {
        background-image: url('../../img/header_background_7.webp');
    }
}
.jumbo {
    max-width: 900px;
    width: 90%;
    padding-top: 65px;
    padding-bottom: 45px;
}
.bigger {
    color: #fff;
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 1px;
    font-weight: 700;
    text-shadow: 1px 1px 1px #2e2e2e, 1px -1px 1px #2e2e2e, -1px 1px 1px #2e2e2e, -1px -1px 1px #2e2e2e;
}
.big {
    color: #fff;
    font-family: 'gunny_rewrittenregular';
    font-size: 38px;
    line-height: 42px;
    letter-spacing: 1px;
    font-weight: 700;
    padding-top: 15px;
    margin-bottom: 25px;
    text-shadow: 1px 1px 1px #2e2e2e, 1px -1px 1px #2e2e2e, -1px 1px 1px #2e2e2e, -1px -1px 1px #2e2e2e;
}